import { useState, useId } from 'react';
import {
    Box,
    Button,
    TextField,
    Stack,
    Divider,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
    Flaky as FlakyIcon,
    FileUpload as FileUploadIcon,
    Payment as PaymentIcon,
} from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import ModalComponent from '../../../standarComponents/ModalComponent';
import { getLocalStorage } from '../../../../utils/browserStorage/browserStorage';
import InputUploadFiles from '../../../standarComponents/InputUploadFile';
import ApprovalSection from './approvalSection/ApprovalSection';
import CancelButton from './approvalSection/CancelButton';

const ApprovalModal = ({expense, treasurers})=>{
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [uploadFiles, setUploadFiles] = useState([]);
    const commentId = useId();
    let buttonToShow = { //@todo, mejorar
        PBAC: {
            label: `${t("approve")}/${t("reject")}`,
            showButton: ()=>{
                const user = getLocalStorage("auth-user", true);
                return user.employee?.id == expense.pending_by_id;//@todo, realizar por user_id
            },
            Button: <ModalButton Icon={()=><FlakyIcon/>} setShowModal={setShowModal} label={`${t("approve")}/${t("reject")}`}/>
        },
        PBUP: {
            label: t("upload-proof"),
            showButton: ()=>{
                const user = getLocalStorage("auth-user", true);
                return user.id == expense.user_id;
            },
            Button: <ModalButton Icon={()=><FileUploadIcon/>} setShowModal={setShowModal} label={t("upload-proof")}/>
        },
        PRBT: {
            label: t("upload-proof-of-payment"),
            showButton: ()=>{
                const user = getLocalStorage("auth-user", true);
                return treasurers.find(t=>t.user_id == user.id) != undefined;
            },
            Button: <ModalButton Icon={()=><PaymentIcon/>} setShowModal={setShowModal} label={t("upload-proof-of-payment")}/>
        }
    };

    if((!buttonToShow[expense.status_code]) || (!buttonToShow[expense.status_code].showButton())){
        return <Box sx={{ width: "100%" }}><CancelButton expense={expense}/></Box>;
    }

    return <>
        <Stack direction="column" spacing={1} divider={<Divider/>} sx={{ width: "100%" }}>
            <CancelButton expense={expense}/>
            {buttonToShow[expense.status_code].Button}
        </Stack>
        <ModalComponent show={showModal} setShow={setShowModal} title={buttonToShow[expense.status_code].label}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid xs={12}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid xs={12} display="flex" justifyContent="center">
                                <TextField
                                    id={commentId}
                                    label={t("comment")}
                                    placeholder={t("comment")}
                                    sx={{ width: "80%" }}
                                    multiline
                                />
                            </Grid>
                            <Grid xs={12} display="flex" justifyContent="center">
                                <Box sx={{ width: "80%" }}>
                                    <InputUploadFiles
                                        uploadFiles={uploadFiles}
                                        setUploadFiles={setUploadFiles}
                                    />
                                </Box>
                            </Grid>
                            <Grid xs={12} display="flex" justifyContent="center">
                                <Box sx={{ width: "80%" }}>
                                    <ApprovalSection 
                                        expense={expense}
                                        commentId={commentId}
                                        uploadFiles={uploadFiles}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </ModalComponent>
    </>;
};

const ModalButton = ({Icon, label, setShowModal})=>{//@todo, mejorar
    return <Button
        variant="contained" 
        color="primary" 
        startIcon={<Icon/>} 
        sx={{ width: "100%" }}
        onClick={()=>{
            setShowModal(true)
        }}
    >
        { label }
    </Button>;
};

export default ApprovalModal;