import { useState, memo, useReducer, useEffect, useId } from 'react';
import { useNavigate } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';
import { 
    Stack,
    Skeleton,
    Box,
    Paper,
    BottomNavigation,
    BottomNavigationAction,
    Fab,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// import DataTable, { createTheme } from 'react-data-table-component';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import {
    Add as AddIcon,
    FormatListBulleted as FormatListBulletedIcon,
    Checklist as ChecklistIcon,
    AccountBox as AccountBoxIcon,
    ManageSearch as ManageSearchIcon
} from '@mui/icons-material';
import { purple } from '@mui/material/colors';
import { blhEasRequest } from '../../../utils/request';
import { getLocalStorage } from '../../../utils/browserStorage/browserStorage';
import DataTable from './tableComponents/DataTable';
import { GeneralFilter, DialogAdvanceFilter } from './tableComponents/DataFilters';

const getSearchTypeValues = () => {
    return {
        "all-expense": {
            key: "all-expense",
            url: "api/eas/list"
        },
        "pending-by-me": {
            key: "pending-by-me",
            url: `api/eas/list-pending-by/${getLocalStorage("auth-user", true).id}`
        },
        "requested-by-me": {
            key: "requested-by-me",
            url: `api/eas/list-registered-by/${getLocalStorage("auth-user", true).id}`
        },
        "advance-search": {
            key: "advance-search",
            url: "api/eas/list-with-filters"
        }
    }
};

const getEasList = async (selectPage, selectRowsPerPage, searchType, searchParams)=>{
    switch(searchType.key){
        case "advance-search":
            return await blhEasRequest({ withToken: true })
                .post(
                    searchType.url,
                    searchParams,
                    {
                        params: {
                            page: selectPage,
                            recordByPage: selectRowsPerPage,
                        }
                    }
                );
        default:
            return await blhEasRequest({ withToken: true })
                .get(searchType.url,{
                    params: {
                        page: selectPage,
                        recordByPage: selectRowsPerPage,
                        ...searchParams
                    }
                });
    }
};

const Loading = ()=>{
    return <Stack spacing={1}>
        <Skeleton variant="text" sx={{ width: "13%" }} height={15}/>
        <Box sx={{ display: "flex"}}>
            <Skeleton variant="rectangular" sx={{ width: "20%" }} height={30}/>
            <Skeleton variant="rounded" sx={{ width: "5%", mx:1 }} height={30}/>
        </Box>
        <Skeleton variant="rectangular" sx={{ width: "100%" }} height={200}/>
    </Stack>;
};

const FloatingAddNewButton = memo(()=>{
    const navigate = useNavigate();
    const { t } = useTranslation(); 
    return <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <Fab size="small" variant="extended" color="primary" onClick={()=>navigate("create")} aria-label={t("create-expense")} sx={{ position: "fixed", bottom: 60, right: 0}}>
            <AddIcon/> <Typography variant="overline" sx={{ textTransform: "capitalize" }}>{t("create-expense")}</Typography>
        </Fab>
    </Box>;
});

const ListNavigation = memo(({ value, setValue })=>{
    const { t } = useTranslation();
    return <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
                console.log("newValue", newValue);
                setValue(newValue);
            }}
        >
            <BottomNavigationAction 
                label={t("all-expenses")}
                value="all-expense"
                icon={<FormatListBulletedIcon/>}
                sx={{ textTransform: "capitalize" }}
            />
            <BottomNavigationAction 
                label={t("pending-by-me")}
                value="pending-by-me"
                icon={<ChecklistIcon/>}
                sx={{ textTransform: "capitalize" }}
            />
            <BottomNavigationAction 
                label={t("requested-by-me")}
                value="requested-by-me"
                icon={<AccountBoxIcon/>}
                sx={{ textTransform: "capitalize" }}
            />
            <BottomNavigationAction 
                label={t("advance-search")}
                value="show-advance-search"
                icon={<ManageSearchIcon/>}
                sx={{ textTransform: "capitalize", color: purple[400] }}
            />
        </BottomNavigation>
    </Paper>
});

const ListExpense = ()=>{
    const [openAdvanceFilter, setOpenAdvanceFilter] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchWord, setSearchWord] = useReducer((state, newVal)=>{
        let valToSet = newVal;
        if(valToSet.search == ''){
            delete valToSet.search;
        }
        return valToSet;
    }, {});
    const [searchType, setSearchType] = useReducer((state, newVal)=>{
        if(newVal == "show-advance-search"){
            setOpenAdvanceFilter(true);
            return state;
        }
        setPage(0);
        if(!getSearchTypeValues()[newVal]){
            return getSearchTypeValues()['all-expense'];
        }
        return getSearchTypeValues()[newVal];
    }, getSearchTypeValues()['all-expense']);

    const { isPending, isLoading, isFetching, error, data } = useQuery({
        queryKey: ['expense-list', [page, rowsPerPage, searchType, searchWord]],
        queryFn: () => getEasList(page+1, (rowsPerPage == -1) ? "all" : rowsPerPage, searchType, searchWord),
        keepPreviousData: true
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };    

    const handlePerRowsChange = ({target}) => {
        setRowsPerPage(parseInt(target.value, 10));
        setPage(0);
    };

    if(isPending){
        return <Loading/>;
    }

    const list = data.data.list;
    const totalRows = data.data.count;

    return <>
        <Box sx={{ display: "flex" }}>
            {/* @todo, mejorar seccion */}
            { searchType.key != "advance-search" && <GeneralFilter 
                value={searchWord.search}
                setValue={(val)=>{
                    setSearchWord({ search: val });
                }}
            /> }
            <DialogAdvanceFilter 
                open={openAdvanceFilter} 
                setOpen={setOpenAdvanceFilter} 
                onSetDataFilter={(values)=>{
                    setSearchType('advance-search');
                    setSearchWord({ search: '', ...values });
                    setOpenAdvanceFilter(false);
                }} 
            />
        </Box>
        <DataTable
            rows={list}
            totalRows={totalRows || list.length}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handlePerRowsChange}
            page={page}
            setPage={handleChangePage}
        />
        <ListNavigation
            value={searchType.key}
            setValue={setSearchType}
        />
        <FloatingAddNewButton/>
    </>
};

export default ListExpense;